.selected-goods {
    width: 940px;
    height: 195px;
    border-top: 1px solid #E0E0E0;
    border-top: 1px solid color(display-p3 0.8792 0.8792 0.8792);
    border-bottom: 1px solid #E0E0E0;
    border-bottom: 1px solid color(display-p3 0.8792 0.8792 0.8792);
    background: #FFF;
    background: color(display-p3 1 1 1);
    display: grid;
    grid-template-columns: auto max-content;
    justify-content: space-between;
    align-items: center;
    padding: 15.5px 22px;
    box-sizing: border-box;
}

.goods-info {
    display: flex;
    align-items: center;
    gap: 64.5px;
}

.goods-photo img {
    max-width: 163.5px;
    height: 147.25px;
    display: block;
    border-radius: 5px;
}

.goods-name {
    width: 342px;
    height: 51px;
    color: #000;
    color: color(display-p3 0 0 0);
    font-family: Roboto Flex;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
}

.goods-price {
    color: #000;
    color: color(display-p3 0 0 0);
    font-family: Roboto Flex;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 42px;
}

.goods-quantity {
    display: flex;
    gap: 8px;
}

.quantity-btn {
    display: flex;
    width: 30px;
    height: 30px;
    padding: 3px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 5px;
    background: #E0E0E0;
    background: color(display-p3 0.8792 0.8792 0.8792);
    cursor: pointer;
    box-sizing: border-box;
}

.quantity-number {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: #99826B;
    color: color(display-p3 0.5843 0.5137 0.4314);
    font-family: Roboto Flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

@media (max-width: 800px) {
    .selected-goods {
        width: 100%;
        height: 66px;
        padding: 5.6px 7.5px;
    }

    .goods-name {
        width: max-content;
        height: max-content;
        line-height: 10px;
        font-size: 11px;
    }

    .goods-photo img {
        width: 56.5px;
        height: 50.25px;
    }

    .goods-info {
        display: grid;
        grid-template-columns: max-content auto;
        gap: 22px;
    }

    .goods-price {
        color: #000;
        color: color(display-p3 0 0 0);
        font-family: Roboto Flex;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        margin-bottom: 0;
    }

    .quantity-number {
        display: flex;
        padding: 2.1px;
        justify-content: center;
        align-items: center;
        gap: 1.362px;
        font-size: 7px;
    }

    .quantity-btn {
        display: flex;
        width: 10.213px;
        height: 10.213px;
        padding: 1.021px 2.723px;
        justify-content: center;
        align-items: center;
        gap: 1.362px;
        border-radius: 1.702px;
        font-size: 7px;
        background: #99826B;
        background: color(display-p3 0.5843 0.5137 0.4314);
        color: #FFF;
        color: color(display-p3 1 1 1);
    }

    .goods-quantity {
        gap: 2.723px;
    }
}
