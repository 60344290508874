.beer-block {
    position: relative;
    border-radius: 40px;
    background: #F1F1F1;
    background: color(display-p3 0.9451 0.9451 0.9451);
    margin-top: 64px;
}

.beer-header {
    position: relative;
    display: flex;
    padding: 52px 70px 30px 70px;
}

.position {
    width: 90.5vw;
    top: -22%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.position2 {
    width: 90.5vw;
    top: -22%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title-sort {
    display: flex;
    gap: 59px;
}

.beer-title {
    color: #000;
    color: color(display-p3 0 0 0);
    line-height: 1.2;
    text-align: center;
    font-family: Roboto Flex;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.btns {
    display: flex;
    gap: 8px;
    align-items: center;
}

.selected {
    border: none;
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 10px;
    background: #000;
    background: color(display-p3 0 0 0);
    color: #FFF;
    color: color(display-p3 1 1 1);
    font-family: Roboto Flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.type-btn {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 10px;
    border: 1px solid #F1F1F1;
    border: 1px solid color(display-p3 0.9451 0.9451 0.9451);
    background: #FFF;
    background: color(display-p3 1 1 1);
    color: #000;
    color: color(display-p3 0 0 0);
    font-family: Roboto Flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.filltes {
    display: flex;
    position: relative;
    gap: 8px;
}

.fillter-btn {
    border: none;
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 10px;
    background: #000;
    background: color(display-p3 0 0 0);
    color: #FFF;
    color: color(display-p3 1 1 1);
    font-family: Roboto Flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.togled-fillter-btn {
    border: none;
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 10px;
    background: #99826B;
    background: color(display-p3 0.5843 0.5137 0.4314);
    color: #FFF;
    color: color(display-p3 1 1 1);
    font-family: Roboto Flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.card-container {
    padding: 0 70px 35px 70px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
    gap: 20px;
}

.menu-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mobile-filter-btn {
    display: inline-flex;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid #F1F1F1;
    border: 1px solid color(display-p3 0.9451 0.9451 0.9451);
    background: #FFF;
    background: color(display-p3 1 1 1);
    cursor: pointer;
}

.active-mobile-filter-btn {
    display: inline-flex;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid #F1F1F1;
    border: 1px solid color(display-p3 0.9451 0.9451 0.9451);
    background: #FFF;
    background: color(display-p3 1 1 1);
    cursor: pointer;
    background: #99826B;
    background: color(display-p3 0.5843 0.5137 0.4314);
}

.mobile-filters-block {
    position: absolute;
    top: 110%;
    right: 61.5%;
    z-index: 30;
}

.filter-snacks-block {
    position: absolute;
    top: 110%;
    right: 0%;
    z-index: 30;
    display: flex;
    width: 185px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-radius: 10px;
    background: #FFF;
    background: color(display-p3 1 1 1);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 4px 12px 0px color(display-p3 0 0 0 / 0.12);
}

.beer-cart-btn {
    position: fixed;
    bottom: -1px;
    left: -1px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-family: Roboto Flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 1px solid #F1F1F1;
    border: 1px solid color(display-p3 0.9451 0.9451 0.9451);
    background: #FFF;
    background: color(display-p3 1 1 1);
    cursor: pointer;
    background: #99826B;
    background: color(display-p3 0.5843 0.5137 0.4314);
}

.circle {
    width: 13px;
    height: 13px;
    border: 2px solid #FFF;
    border-radius: 50%;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-family: Roboto Flex;
}

@media (max-width: 800px) {



    .beer-block {
        margin-top: 25px;
    }

    .beer-title {
        width: 127px;
        color: #000;
        color: color(display-p3 0 0 0);
        font-family: Roboto Flex;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }

    .beer-header {
        padding: 20px;
    }

    .position {
        width: 85vw;
        top: -35%;
    }

    .position2 {
        width: 85vw;
        top: -25%;
    }

    .card-container {
        padding: 0 20px 15px 20px;
        gap: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }


}
