.fixed-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    height: 100px;
    padding: 42px 85px 33px 70px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    background: #FFF;
    background: color(display-p3 1 1 1);
    box-sizing: border-box;
    z-index: 1000;
}

.your-menu {
    /* Стили вашего меню */
    transition: all 0.3s ease;
  }

.navbar {
    display: flex;
    height: 100px;
    padding: 42px 85px 33px 70px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    background: #FFF;
    background: color(display-p3 1 1 1);
}

.menu-items {
    display: flex;
    height: 20px;
    justify-content: flex-end;
    align-items: center;
    gap: 75px;
}

.menu-item, .menu-item a {
    color: #000;
    color: color(display-p3 0 0 0);
    line-height: 1.2;
    text-align: center;
    font-family: Roboto Flex;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    text-decoration: none;
}

#active {
    display: block;
}

#nonactive {
    display: none;
}

.menu-btn {
    display: flex;
    padding: 14px 11px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 10px;
    border: 1px solid #F1F1F1;
    border: 1px solid color(display-p3 0.9451 0.9451 0.9451);
    background: #FFF;
    background: color(display-p3 1 1 1);
    cursor: pointer;
    z-index: 3;
}

.active-menu-btn {
    border: none;
    display: flex;
    padding: 14px 11px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 10px;
    background: #99826B;
    background: color(display-p3 0.5843 0.5137 0.4314);
    cursor: pointer;
    z-index: 3;
}

@media (max-width: 800px) {

    .navbar {
        position: relative;
        display: flex;
        padding: 5px 20px;
        justify-content: space-between;
        align-items: center;
    }
}
