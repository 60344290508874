.profile-elements {
    display: flex;
    justify-content: space-between;
    padding: 0 70px 70px 70px;
}

.save-btn {
    border: none;
    width: 135px;
    height: 64px;
    display: flex;
    padding: 24px 32px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 10px;
    background: #99826B;
    background: color(display-p3 0.5843 0.5137 0.4314);
    color: #FFF;
    color: color(display-p3 1 1 1);
    font-family: Roboto Flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    margin-top: 30px;
}

.metka {
    position: absolute;
    z-index: 3000000;
    width: 50px;
    height: 50px;
    top: 45%;
    right: 45%;
}

.nahuy-hohlov {
    height: 25px;
    width: 237px;
    position: absolute;
    z-index: 3000000;
    color: #FFF;
    bottom: 0;
    right: 0;
    border-radius: 0 0 10px 0;
    text-align: center;
    background-color: #99826B;
    font-family: Roboto Flex;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leaflet-container {
    border-radius: 10px 10px 10px 10px;
}

#profile-address {
    width: 514px;
    height: 64px;
}

#profile-street {
    width: 377px;
    height: 64px;
    margin-top: 8px;
}

#profile-house {
    width: 116px;
    height: 64px;
    margin-top: 8px;
    margin-bottom: 3px;
}

.row-update {
    display: flex;
    align-items: center;
    gap: 24px;
}

.update-title {
    color: #000;
    color: color(display-p3 0 0 0);
    font-family: Roboto Flex;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 30px;
}

.save-btn-update {
    display: flex;
    padding: 24px 32px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 10px;
    background: #99826B;
    opacity: 0.5;
    background: color(display-p3 0.5843 0.5137 0.4314);
    color: #FFF;
    color: color(display-p3 1 1 1);
    font-family: Roboto Flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 30px;
}

@media (max-width: 800px) {
    .profile {
        height: 150vh;
        padding: 0;
        box-sizing: border-box;
        background: #F1F1F1;
        background: color(display-p3 0.9451 0.9451 0.9451);
    }

    .profile-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        background: #F1F1F1;
        background: color(display-p3 0.9451 0.9451 0.9451);
        padding: 89px 20px 25px 20px;
    }

    .save-btn {
        width: 320px;
        height: 60px;
        font-size: 14px;
        padding: 22px 32px;
    }

    .save-btn-update {
        width: 320px;
        height: 60px;
        font-size: 14px;
        padding: 22px 32px;
    }

    .adress-title {
        margin-top: 32px;
    }

    #profile-street {
        width: auto;
        height: 60px;
    }

    #profile-house {
        width: 80px;
        height: 60px;
    }
}
