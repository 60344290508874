.paper-delivery-block {
    padding: 15px 70px 25px 70px;
    display: flex;
    flex-direction: column;
}

.about-delivery {
    width: 100%;
    height: 400px;
    border-radius: 40px;
    background: #99826B;
    background: color(display-p3 0.5843 0.5137 0.4314);
    box-sizing: border-box;
    padding: 112px 51px;
}

.about-delivery-title {
    color: #FFF;
    color: color(display-p3 1 1 1);
    font-family: Roboto Flex;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 40px;
}

.about-delivery-description-text {
    width: 412px;
    color: #FFF;
    color: color(display-p3 1 1 1);
    font-family: Roboto Flex;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.delivery-row-2 {
    margin-top: 20px;
    display: flex;
    gap: 20px;
}

.delivery-places {
    position: relative;
    min-height: 644px;
    width: 50%;
    border-radius: 40px;
    background: #F1F1F1;
    background: color(display-p3 0.9451 0.9451 0.9451);
}

.delivery-title-text {
    padding: 68px 100px 68px 51px;
    box-sizing: border-box;
}

.delivery-title {
    color: #000;
    color: color(display-p3 0 0 0);
    font-family: Roboto Flex;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 40px;
}

.delivery-description-text {
    max-width: 489px;
    color: #000;
    color: color(display-p3 0 0 0);
    font-family: Roboto Flex;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.delivery {
    width: 50%;
}

.delivery-offers {
    min-height: 358px;
    padding: 68px 100px 68px 51px;
    box-sizing: border-box;
    border-radius: 40px;
    background: #F1F1F1;
    background: color(display-p3 0.9451 0.9451 0.9451);
    border-radius: 40px;
    background: #F1F1F1;
    background: color(display-p3 0.9451 0.9451 0.9451);
    margin-bottom: 20px;
}

.delivery-time {
    min-height: 266px;
    padding: 68px 100px 68px 51px;
    box-sizing: border-box;
    border-radius: 40px;
    background: #F1F1F1;
    background: color(display-p3 0.9451 0.9451 0.9451);
}

.delivery-policy {
    margin-top: 20px;
    position: relative;
    width: 100%;
    min-height: 289px;
    border-radius: 40px;
    background: #F1F1F1;
    background: color(display-p3 0.9451 0.9451 0.9451);
    padding: 61px 0 58px 61px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}

.delivery-places-map {
    position: absolute;
    bottom: -5px;
    width: 100%;
}

iframe {
    width: 100%;
    height: 400px;
    border: none;
    border-radius: 0 0 40px 40px;
}

.delivery-policy-photo {
    position: absolute;
    right: 10%;
    bottom: -5px;
    z-index: 0;
}

@media (max-width: 800px) {

    .paper-delivery-block {
        padding: 10px 20px 20px 20px;
    }

    .about-delivery {
        height: 100px;
        padding: 21px 12px;
        border-radius: 3.975px;
    }

    .about-delivery-title {
        font-size: 18px;
        margin-bottom: 8px;
    }

    .about-delivery-description-text {
        width: 100%;
        font-size: 11px;
    }

    .delivery-row-2 {
        flex-direction: column;
        gap: 8px;
    }

    .delivery-places {
        display: flex;
        width: 100%;
        min-height: 124px;
        border-radius: 4px;
    }

    .delivery-title-text {
        padding: 23px 31px 22px 10px;
    }

    .delivery-title {
        font-size: 14px;
        margin-bottom: 8px;
    }

    .delivery-description-text {
        font-size: 8px;
        max-width: 126px;
    }

    iframe {
        height: 150px;
        border: none;
        border-radius: 0 4px 4px 0;
    }

    .delivery-places-map {
        height: 150px;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 50%;
    }

    .delivery {
        display: flex;
        gap: 10px;
        width: 100%;
    }

    .delivery-offers {
        min-height: 124px;
        width: 50%;
        padding: 30px 25.55px 31px 13px;
        border-radius: 4px;
        margin-bottom: 0;
    }

    .delivery-time {
        min-height: 124px;
        width: 50%;
        padding: 30px 25.55px 31px 13px;
        border-radius: 4px;
    }

    .delivery-policy {
        margin-top: 4px;
        min-height: 109px;
        padding: 27px 35px 27px 13px;
        border-radius: 4px;
    }

    .first-pic {
        width: 75px;
        height: 55px;
    }

    .second-pic {
        width: 75px;
        height: 75px;
    }
}
