.adress {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E0E0E0;
    padding: 70px 0;
}

.address-form {
    position: relative;
    width: 970px;
    height: 600px;
    border-radius: 10px;
    background: #FFF;
    background: color(display-p3 1 1 1);
    padding: 60px 76px 60px 50px;
    box-sizing: border-box;
    display: flex;
    gap: 47px;
}

.address-form-inputs {
    display: flex;
    flex-direction: column;
}

.adress-title {
    color: #000;
    color: color(display-p3 0 0 0);
    font-family: Roboto Flex;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 20px;
}

.adress-btn {
    width: 135px;
    height: 64px;
    display: flex;
    padding: 24px 32px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 10px;
    background: #99826B;
    background: color(display-p3 0.5843 0.5137 0.4314);
    box-sizing: border-box;
    color: #FFF;
    color: color(display-p3 1 1 1);
    font-family: Roboto Flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    margin-top: 225px;
    cursor: pointer;
}

.address-input {
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    border: 1px solid color(display-p3 0.8792 0.8792 0.8792);
    background: #FFF;
    background: color(display-p3 1 1 1);
    color: rgba(0, 0, 0, 0.50);
    color: color(display-p3 0 0 0 / 0.50);
    font-family: Roboto Flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 22px 25px 23px 25px;
    box-sizing: border-box;
}

.address-input:focus {
    border: 1px solid #99826B;
    border: 1px solid color(display-p3 0.5843 0.5137 0.4314);
    background: #FFF;
    background: color(display-p3 1 1 1);
}

#adress {
    width: 389px;
    height: 64px;
}

#street {
    width: 273px;
    height: 64px;
    margin-top: 8px;
}

#house {
    width: 96px;
    height: 64px;
    margin-top: 8px;
    margin-bottom: 3px;
}

.map {
    position: absolute;
    top: 0;
    right:0;
}

.metka {
    position: absolute;
    z-index: 3000000;
    width: 50px;
    height: 50px;
    top: 45%;
    right: 45%;
}

.nahuy-hohlov {
    height: 25px;
    width: 237px;
    position: absolute;
    z-index: 3000000;
    color: #FFF;
    bottom: 0;
    right: 0;
    border-radius: 0 0 10px 0;
    text-align: center;
    background-color: color(display-p3 0.5843 0.5137 0.4314);
    font-family: Roboto Flex;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leaflet-container {
    border-radius: 0 10px 10px 0;
}

.container {
    width: 300px;
    height: 150px;
    background: linear-gradient(to right, #f0f0f0 50%, #fff 50%);
    margin: 50px auto;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 3000000;

}

.message-container {
    position: absolute;
    max-width: 300px;
    height: 10px;
    background-color: rgb(243, 86, 86);
    color: #fff;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 45%;
    right: 25%;
    z-index: 3000000;
    box-sizing: border-box;
  }

  .message-triangle {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgb(243, 86, 86);
  }

  .message-text {
    font-size: 15px;
    line-height: 1.4;
  }

.close {
    cursor: pointer;
    z-index: 3000000;
}

.already-registrated {
    font-family: Roboto Flex;
    font-size: 12px;
    color: red;
    margin-left: 5px;
}

@media (max-width: 800px) {
    .address-form {
        height: 125vh;
        width: 100vw;
        box-sizing: border-box;
        background: #F1F1F1;
        background: color(display-p3 0.9451 0.9451 0.9451);
        padding: 0 20px 60px 20px;
        flex-direction: column;
        gap: 3px;
    }

    .adress-title {
        font-size: 20px;
    }

    .adress-btn {
        width: 100%;
        height: 60px;
        font-size: 14px;
        padding: 22px 32px;
        margin-top: 16px;
    }

    .input {
        height: 60px;
        margin-bottom: 8px;
        font-size: 12px;
    }

    #adress {
        width: 100%;
        height: 60px;
    }

    #street {
        width: auto;
        height: 60px;
        margin-top: 8px;
    }

    #house {
        width: 80px;
        height: 60px;
        font-size: 12px;
    }

    .row {
        width: 100%;
        display: grid;
        grid-template-columns: 3fr 1fr;
    }

    .map {
        position: relative;
    }

    .leaflet-container {
        border-radius: 10px;
    }
}
