.filter-sort-block {
    position: absolute;
    top: 120%;
    left: 0px;
    display: flex;
    width: 185px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-radius: 10px;
    background: #FFF;
    background: color(display-p3 1 1 1);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 4px 12px 0px color(display-p3 0 0 0 / 0.12);
    z-index: 2;
}

.filter-type {
    display: flex;
    align-items: center;
}

.filter-name {
    color: #000;
    color: color(display-p3 0 0 0);
    font-family: Roboto Flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-left: 8px;
}

.filter-type input {
    height: 16px;
    width: 16px;
    display: inline-block;
    position: relative;
    border-radius: 4px;
    border: 1px solid #E0E0E0;
    border: 1px solid color(display-p3 0.8792 0.8792 0.8792);
    background: #FFF;
    background: color(display-p3 1 1 1);
    box-sizing: border-box;
}

[type=checkbox]:checked {
    height: 16px;
    width: 16px;
    content: url('img/stroke.png');
    color: #FFF;
    border-radius: 4px;
    border: 1px solid #99826B;
    border: 1px solid color(display-p3 0.5843 0.5137 0.4314);
    background: #99826B;
    background: color(display-p3 0.5843 0.5137 0.4314);
    box-sizing: border-box;
}

.apply-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: #99826B;
    color: color(display-p3 0.5843 0.5137 0.4314);
    font-family: Roboto Flex;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    cursor: pointer;
}

.filter-category {
    color: #000;
    color: color(display-p3 0 0 0);
    font-family: Roboto Flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.filter-category-country {
    margin-top: 28px;
    color: #000;
    color: color(display-p3 0 0 0);
    font-family: Roboto Flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}
