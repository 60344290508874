.product {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Полупрозрачный фон */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
}

.product-card {
    position: relative;
    display: flex;
    width: 970px;
    border-radius: 10px;
    background: #FFF;
    background: color(display-p3 1 1 1);
    padding-bottom: 20px;
}

.close-product-card {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    background-image: url("img/Vector.png");
    width: 20px;
    height: 20px;
}

.product-card-photo {
    display: flex;
    justify-content: center;
    border-radius: 10px 0 0 10px;
    width: 485px;
    height: 600px;
    background-size: cover;
    padding: 60px 0 60px 0;
    box-sizing: border-box;
}

.product-card-photo img {
    max-width: 100%;
    height: 100%;
}

.product-card-text {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
}

.product-card-name {
    width: 380px;
    height: 72px;
    margin-top: 60px;
    color: #000;
    color: color(display-p3 0 0 0);
    font-family: Roboto Flex;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.product-card-description {
    margin-top: 15px;
    color: #000;
    color: color(display-p3 0 0 0);
    font-family: Roboto Flex;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 12px */
}

.product-card-price {
    margin: 35px 0;
    color: #000;
    color: color(display-p3 0 0 0);
    font-family: Roboto Flex;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 25px */
    text-transform: uppercase;
}

.product-card-about-title {
    margin-bottom: 10px;
    color: #000;
    color: color(display-p3 0 0 0);
    font-family: Roboto Flex;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.product-card-about-text {
    width: 378px;
    color: #000;
    color: color(display-p3 0 0 0);
    font-family: Roboto Flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 14.4px */
    margin-bottom: 15px;
}

.product-card-name-taste-title {
    color: #000;
    color: color(display-p3 0 0 0);
    font-family: Roboto Flex;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
}

.product-card-taste-text {
    width: 339px;
    color: #000;
    color: color(display-p3 0 0 0);
    font-family: Roboto Flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
}

.add-btn {
    width: 120px;
    height: 40px;
    margin-top: 43px;
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 10px;
    background: #99826B;
    background: color(display-p3 0.5843 0.5137 0.4314);
    color: #FFF;
    color: color(display-p3 1 1 1);
    font-family: Roboto Flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.card-product-btn {
    width: 102px;
    height: 40px;
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 10px;
    background: #99826B;
    background: color(display-p3 0.5843 0.5137 0.4314);
    box-sizing: border-box;
    color: #FFF;
    color: color(display-p3 1 1 1);
    font-family: Roboto Flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    margin-top: 43px;
    margin-bottom: 30px;
}

@media (max-width: 800px) {
    .product-card {
        position: relative;
        width: 322px;
        height: 578px;
        flex-direction: column;
        padding-bottom: 0;
    }

    .close-product-card {
        display: flex;
        width: 24px;
        height: 24px;
        padding: 3px 8px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        flex-shrink: 0;
        border-radius: 5px;
        background: #FFF;
        background: color(display-p3 1 1 1);
        box-sizing: border-box;
        top: 10px;
        right: 10px;
    }

    .product-card-photo {
        border-radius: 10px 10px 0 0;
        width: 322px;
        height: 240px;
    }

    .product-card-text {
        padding-left: 20px;
    }

    .product-card-name {
        width: 99%;
        height: 46px;
        margin-top: 25px;
        color: #000;
        color: color(display-p3 0 0 0);
        font-family: Roboto Flex;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 0;
    }

    .product-card-description {
        color: #000;
        color: color(display-p3 0 0 0);
        font-family: Roboto Flex;
        font-size: 8px;
        font-style: normal;
        font-weight: 500;
        margin-top: 10px;
    }

    .product-card-price {
        position: absolute;
        margin-top: 60px;
        left: 20px;
        top: 42%;
        color: #000;
        color: color(display-p3 0 0 0);
        font-family: Roboto Flex;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .product-card-about-title {
        color: #000;
        color: color(display-p3 0 0 0);
        font-family: Roboto Flex;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 24px;
        margin-bottom: 10px;
    }

    .product-card-about-text {
        width: 280px;
        color: #000;
        color: color(display-p3 0 0 0);
        font-family: Roboto Flex;
        font-size: 8px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        margin-bottom: 20px;
    }

    .product-card-name-taste-title {
        color: #000;
        color: color(display-p3 0 0 0);
        font-family: Roboto Flex;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 10px;
    }

    .product-card-taste-text {
        width: 90%;
        height: 14px;
        color: #000;
        color: color(display-p3 0 0 0);
        font-family: Roboto Flex;
        font-size: 8px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
    }

    .add-btn {
        width: 50%;
        height: 24px;
        display: flex;
        padding: 6px 41px;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        background: #99826B;
        background: color(display-p3 0.5843 0.5137 0.4314);
        box-sizing: border-box;
        color: #FFF;
        color: color(display-p3 1 1 1);
        font-family: Roboto Flex;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 30px;
    }
}