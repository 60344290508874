.input-name {
    color: #000;
    color: color(display-p3 0 0 0);
    font-family: Roboto Flex;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 7px;
    margin-top: 20px;
}

.input {
    width: 514px;
    height: 64px;
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    border: 1px solid color(display-p3 0.8792 0.8792 0.8792);
    background: #FFF;
    background: color(display-p3 1 1 1);
    padding: 22px 25px 23px 25px;
    box-sizing: border-box;
    font-family: Roboto Flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    outline: none;
}

.input:focus {
    border: 1px solid #99826B;
    border: 1px solid color(display-p3 0.5843 0.5137 0.4314);
    background: #FFF;
    background: color(display-p3 1 1 1);
}

.row {
    display: flex;
    gap: 20px;
}

#created_password {
    width: 247px;
    height: 64px;
}

#repeated_password {
    width: 247px;
    height: 64px;
}

.mis-match {
    margin-left: 5px;
    margin-top: 5px;
    color: red;
    font-family: Roboto Flex;
    font-size: 12px;
}

.input-mis-match {
    border: 1px solid red;
}

@media (max-width: 800px) {
    .input {
        width: 100%;
        height: 60px;
        margin-bottom: 8px;
        font-size: 12px;
    }

    #created_password {
        width: 100%;
        height: 60px;
        font-size: 12px;
    }

    #repeated_password {
        width: 100%;
        height: 60px;
        font-size: 12px;
    }

    #house {
        width: 80px;
        height: 60px;
        font-size: 12px;
    }

    .row {
        gap: 8px;
    }

    .input-flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .mis-match {
        margin-left: 5px;
        margin-top: 2px;
        margin-bottom: 15px;
        color: red;
        font-family: Roboto Flex;
        font-size: 12px;
    }
}
