.card {
    position: relative;
    width: 310px;
    height: 420px;
    flex-shrink: 0;
    border-radius: 7px;
    background: #FFF;
    background: color(display-p3 1 1 1);
    box-sizing: border-box;
    cursor: pointer;
}

.card-text {
    padding: 0 20px;
}

.card-photo {
    display: flex;
    justify-content: center;
    width: 310px;
    height: 205px;
    border-radius: 5px 5px 0 0;
}

.card-photo img {
    max-width: 100%;
    height: 100%;
    display: block;
    margin-top: 10px;
}

.price {
    color: #000;
    color: color(display-p3 0 0 0);
    line-height: 1.2;
    font-family: Roboto Flex;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 25px */
    text-transform: uppercase;
    margin-top: 25px;
}

.name {
    color: #000;
    color: color(display-p3 0 0 0);
    font-family: Roboto Flex;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 20px */
    margin-top: 20px;
}

.description {
    color: #000;
    color: color(display-p3 0 0 0);
    font-family: Roboto Flex;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 12px */
    margin-top: 15px;
    margin-bottom: 30px;
}

.card-btn {
    border: none;
    position: absolute;
    bottom: 0px;
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 10px;
    background: #99826B;
    background: color(display-p3 0.5843 0.5137 0.4314);
    color: #FFF;
    color: color(display-p3 1 1 1);
    font-family: Roboto Flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    margin-bottom: 24px;
}

.card-btn-veiw {
    border: none;
    position: absolute;
    bottom: 0px;
    right: 20px;
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 10px;
    background: #99826B;
    background: color(display-p3 0.5843 0.5137 0.4314);
    color: #FFF;
    color: color(display-p3 1 1 1);
    font-family: Roboto Flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    margin-bottom: 24px;
}


.add-btns {
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    bottom: 30px;
}

.update {
    display: none;
}

.cart-btn {
    border: none;
    display: flex;
    width: 30px;
    height: 30px;
    padding: 3px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 5px;
    background: #99826B;
    background: color(display-p3 0.5843 0.5137 0.4314);
    color: #FFF;
    color: color(display-p3 1 1 1);
    cursor: pointer;
}

.added-quantity {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: #99826B;
    color: color(display-p3 0.5843 0.5137 0.4314);
    font-family: Roboto Flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.add-to-cart-btn {
    margin-left: 8px;
    display: flex;
    height: 30px;
    padding: 3px 15px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 5px;
    background: #99826B;
    background: color(display-p3 0.5843 0.5137 0.4314);
    cursor: pointer;
}

.question {
    position: absolute;
    top: 8px;
    right: 3px;
    width: 19px;
    height: 19px;
}

@media (max-width: 800px) {

    .card {
        width: 150px;
        height: 205px;
    }

    .card-photo {
        width: 150px;
        height: 100px;
    }

    .card-text {
        padding: 0 9px;
    }

    .price {
        color: #000;
        color: color(display-p3 0 0 0);
        font-family: Roboto Flex;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        text-transform: uppercase;
        margin-top: 20px;
    }

    .name {
        color: #000;
        color: color(display-p3 0 0 0);
        font-family: Roboto Flex;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        margin-top: 8px;
    }

    .description {
        color: #000;
        color: color(display-p3 0 0 0);
        font-family: Roboto Flex;
        font-size: 7px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        margin-top: 8px;
        margin-bottom: 15px;
    }

    .card-btn {
        border: none;
        width: 134px;
        height: 24px;
        display: flex;
        padding: 6px 41px;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        background: #99826B;
        background: color(display-p3 0.5843 0.5137 0.4314);
        color: #FFF;
        color: color(display-p3 1 1 1);
        font-family: Roboto Flex;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        box-sizing: border-box;
        bottom: -10px;
    }

    .cart-btn {
        display: flex;
        width: 24px;
        height: 24px;
        padding: 3px 8px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 5px;
        background: #99826B;
        background: color(display-p3 0.5843 0.5137 0.4314);
        color: #FFF;
        color: color(display-p3 1 1 1);
    }

    .added-quantity {
        color: #99826B;
        color: color(display-p3 0.5843 0.5137 0.4314);
        font-family: Roboto Flex;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .add-to-cart-btn {
        display: flex;
        height: 24px;
        padding: 3px 10px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 5px;
        background: #99826B;
        background: color(display-p3 0.5843 0.5137 0.4314);
        margin-left: 0;
    }

    .add-btns {
        gap: 20px;
        width: 88%;
        justify-content: center;
        bottom: 5px;
    }

}
