.main-carousel {
    padding: 0 70px 0 70px;
}

.carousel-container {
    position: relative;
    overflow: hidden;
    width: 100%; /* Установите ширину карусели по вашему усмотрению */
    height: 400px; /* Установите высоту карусели в 400 пикселей */
    margin: 0 auto; /* Центрируйте карусель на странице, если нужно */
}

.carousel {
    display: flex;
    transition: transform 0.5s ease;
}

.slide {
    flex: 0 0 100%;
}

.slide img {
    width: 100%;
    height: 400px;
    display: block;
    border-radius: 10px;
    background-size: cover;
}

.dots-container {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 5px;
    cursor: pointer;
}

.dot.active {
    background-color: #FFFFFF;
}

@media (max-width: 800px) {
    .main-carousel {
        padding: 0 20px 0 20px;
    }

    .carousel-container {
        height: 100px;
    }

    .slide img {
        height: 100px;
    }
}