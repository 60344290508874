.cart {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 50px;
    padding: 0 70px 70px 70px;
}

.cart-title {
    padding: 15px 70px 35px 70px;
    color: #000;
    color: color(display-p3 0 0 0);
    line-height: 1.2;
    font-family: Roboto Flex;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.delivery-data {
    width: 310px;
    height: 584px;
    display: flex;
    flex-direction: column;
}

.delivery-tittle {
    color: #000;
    color: color(display-p3 0 0 0);
    font-family: Roboto Flex;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
}

.delivery-adress {
    width: 310px;
    color: #000;
    color: color(display-p3 0 0 0);
    font-family: Roboto Flex;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-top: 24px;
    margin-bottom: 50px;
}

.payment {
    margin-bottom: 24px;
}

.del-row {
    display: flex;
    align-items: center;
    gap: 8px;
}

.pen {
    width: 14px;
    height: 14px;
    cursor: pointer;
}

.open {
    margin-bottom: 24px;
    cursor: pointer;
}

.change-input {
    width: 310px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    border: 1px solid color(display-p3 0.8792 0.8792 0.8792);
    background: color(display-p3 1 1 1);
    color: rgba(0, 0, 0, 0.50);
    color: color(display-p3 0 0 0 / 0.50);
    font-family: Roboto Flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 50px;
    padding: 15px 20px;
    box-sizing: border-box;
    outline: none;
}

.change-input:focus {
    border: 1px solid #99826B;
    border: 1px solid color(display-p3 0.5843 0.5137 0.4314);
    background: #FFF;
    background: color(display-p3 1 1 1);
}

.delivery-row {
    display: flex;
    justify-content: space-between;
}

.delivery-description {
    color: #000;
    color: color(display-p3 0 0 0);
    font-family: Roboto Flex;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 15px;
}

.delivery-price {
    color: #000;
    color: color(display-p3 0 0 0);
    text-align: right;
    font-family: Roboto Flex;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.total {
    margin-top: 15px;
    margin-bottom: 50px;
}

.final-price {
    color: #000;
    color: color(display-p3 0 0 0);
    text-align: right;
    font-family: Roboto Flex;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    margin-top: 15px;
    margin-bottom: 50px;
}

textarea {
    margin-top: 24px;
    width: 310px;
    height: 120px;
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    border: 1px solid color(display-p3 0.8792 0.8792 0.8792);
    background: #FFF;
    background: color(display-p3 1 1 1);
    color: rgba(0, 0, 0, 0.50);
    color: color(display-p3 0 0 0 / 0.50);
    font-family: Roboto Flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 9px;
    box-sizing: border-box;
    resize: none;
}

.order-btn {
    margin-top: 24px;
    display: flex;
    width: 310px;
    padding: 20px 32px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 10px;
    background: #99826B;
    background: color(display-p3 0.5843 0.5137 0.4314);
    color: #FFF;
    color: color(display-p3 1 1 1);
    font-family: Roboto Flex;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;
}

a {
    text-decoration: none;
}

.new-address-input {
    width: 310px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    border: 1px solid color(display-p3 0.8792 0.8792 0.8792);
    background: color(display-p3 1 1 1);
    color: rgba(0, 0, 0, 0.50);
    color: color(display-p3 0 0 0 / 0.50);
    font-family: Roboto Flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 50px;
    padding: 15px 20px;
    box-sizing: border-box;
    outline: none;
    margin-top: 15px;
}

.new-address-input:focus {
    border: 1px solid #99826B;
    border: 1px solid color(display-p3 0.5843 0.5137 0.4314);
    background: #FFF;
    background: color(display-p3 1 1 1);
}

.cart-map {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}

.cart-blur {
    position: relative;
  }

.cart-blur::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 999;
}

body.no-scroll {
    overflow: hidden;
}

.offered-block {
    padding: 0 70px;
}

.offered-info {
    border-radius: 10px;
    background: #99826B;
    background: color(display-p3 0.5843 0.5137 0.4314);
    width: 100%;
    height: 400px;
    padding: 50px 70px;
    box-sizing: border-box;
}

.offered-info-text {
    width: 553px;
    height: 103px;
    color: #FFF;
    color: color(display-p3 1 1 1);
    font-family: Roboto Flex;
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.offered-info-number-title {
    color: #FFF;
    color: color(display-p3 1 1 1);
    font-family: Roboto Flex;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    margin-top: 79px;
}

.offered-info-number {
    color: #FFF;
    color: color(display-p3 1 1 1);
    font-family: Roboto Flex;
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 21px;
}

@media (max-width: 800px) {

    .cart-paper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .cart {
        flex-direction: column;
        padding: 0 20px 18px 20px;
        gap: 40px;
    }

    .cart-title {
        width: 86%;
        display: flex;
        justify-content: flex-start;
        font-size: 20px;
        padding: 0px 20px 24px 20px;
        /* padding: 0 20px 24px 20px; */
    }

    .delivery-tittle {
        font-size: 20px;
    }

    .delivery-adress {
        width: 320px;
        font-size: 10px;
        margin-top: 5.89px;
        margin-bottom: 40px;
    }

    textarea {
        height: 60px;
        border-radius: 5px;
        border: 1px solid #E0E0E0;
        border: 1px solid color(display-p3 0.8792 0.8792 0.8792);
        background: #FFF;
        background: color(display-p3 1 1 1);
        color: #808080;
        color: color(display-p3 0.502 0.502 0.502);
        font-family: Roboto Flex;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 22px 15px;
        box-sizing: border-box;
        resize: none;
        margin-bottom: 20px;
    }

    .delivery-row {
        margin-top: 15px;
    }

    .total {
        margin-bottom: 0;
    }

    .final-price {
        margin-bottom: 0;
    }

    .order-btn {
        border: none;
        margin-top: 50px;
        display: flex;
        width: 320px;
        padding: 22px 32px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 10px;
        background: #99826B;
        background: color(display-p3 0.5843 0.5137 0.4314);
        color: #FFF;
        color: color(display-p3 1 1 1);
        font-family: Roboto Flex;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .cart-map {
        padding: 20px;
        box-sizing: border-box;
        background: #F1F1F1;
        background: color(display-p3 0.9451 0.9451 0.9451);
        flex-direction: column;
        gap: 3px;
    }

    .offered-block {
        padding: 20px;
    }

    .offered-info {
        height: 200px;
        padding: 20px;
    }

    .offered-info-text {
        font-size: 23px;
    }

    .offered-info-number-title {
        margin-top: 10px;
        font-size: 15px;
    }

    .offered-info-number {
        margin-top: 10px;
        font-size: 23px;
    }

}
